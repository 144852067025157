import { Button, Table } from "antd";
import * as React from "react";
import { usePostJobGetConfig } from "../../../../hooks/api/admin/usePostJobGetConfig";
import { usePostGenerateToken } from "../../../../hooks/api/user/usePostGenerateToken";
import { useUsers } from "../../../../hooks/api/users/useUsers";
import { useAuth } from "../../../../hooks/useAuth";
import { HeadbotRoute } from "../../../../services/Constants/HeadbotRoute";
import { PrimaryButton } from "../../../atoms/Button/PrimaryButton";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { TextInput } from "../../../atoms/TextInput/TextInput";
import { AdminNavigation } from "../AdminNavigation";
import { navigate } from "gatsby";

const objectIdLength = 24;

interface User {
    _id: string;
    email: string;
    modified: string;
}

export const UserListPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { tokenData, setAuthToken } = useAuth();
    const [jobId, setJobId] = React.useState<string>("");
    const postGenerateUserToken = usePostGenerateToken((data) => {
        setAuthToken(data.token);
        navigate(HeadbotRoute.Dashboard);
    });

    const jobConfig = usePostJobGetConfig(jobId, () => {});
    const usersList = useUsers();
    const onJobIdChanged = React.useCallback(
        (_, value: string) => {
            setJobId(value);
        },
        [setJobId]
    );
    const onGetConfig = React.useCallback(() => {
        jobConfig.mutation.mutate();
    }, [jobConfig.mutation]);

    const isConfigButtonDisabled = jobId.length !== objectIdLength;

    const columns = [
        {
            title: "Id",
            dataIndex: "_id",
            key: "_id",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Modified",
            dataIndex: "modified",
            key: "modified",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_text: string, user: User) => (
                <Button
                    type="primary"
                    onClick={() => {
                        postGenerateUserToken.mutation.mutate(user._id);
                    }}
                    disabled={tokenData?.email === user.email}
                >
                    Login
                </Button>
            ),
        },
    ];

    const dataSource: User[] = usersList.data?.users || [];

    if (usersList.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <AdminNavigation />
            <TextInput label="Job id to get config for" placeholder="jobId" value={jobId} onChange={onJobIdChanged} />
            <PrimaryButton disabled={isConfigButtonDisabled} onClick={onGetConfig}>
                Get config
            </PrimaryButton>
            <pre>{jobConfig.mutation.data?.jobSpec}</pre>
            <Table columns={columns} dataSource={dataSource} rowKey="_id" />
        </>
    );
};
